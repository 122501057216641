<template>
  <div class="default-layout">
    <NuxtPage :keepalive="{ include: cacheRoutes }" />
  </div>
</template>

<script setup lang="ts">
const cacheRoutes = ['bookmarks']
const userStore = userUserStore()
const { locale } = useI18n()

if (userStore.currentLocale !== locale.value) {
  userStore.changeLocale(userStore.currentLocale)
}
</script>

<style lang="scss" scoped>
.default-layout {
  --style: w-screen overflow-hidden;
}
</style>
